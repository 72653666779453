<template>
  <div class="wrapper-main">
    <div class="wrapper-content">
      <AccountNumber></AccountNumber>
      <div class="ReferralLinks-content clearfix">
        <div class="select">
          <p :class="{ 'long-text': ['pt', 'es', 'fr', 'vi'].includes(lang) }">{{ $t('referralLinks.campLang') }}</p>
          <el-select v-model="language" @change="languageSelectChange" data-testid="language">
            <el-option
              v-for="item in languageList"
              :key="item.language"
              :label="item.language"
              :value="item.value"
              :data-testid="item.language"
            ></el-option>
          </el-select>
        </div>
        <div class="info-box">
          <div class="copy-line" v-if="accountID">
            <div
              v-for="(link, index) in linkList"
              v-bind:key="link.name"
              :class="{ active: active == index }"
              @click="active = index"
              :data-testid="link.name"
            >
              <p>{{ link.name }}</p>
              <!-- AddToAny END -->
              <div class="copy">
                <div class="img">
                  <el-tooltip placement="bottom-start" popper-class="linkTooltip" visible-arrow="true">
                    <div slot="content">
                      <div class="qrcode-share">
                        <div class="top">
                          <vue-qrcode :color="color" :value="link.url || GLOBAL_REFERRAL_SITE" />
                        </div>
                        <!-- AddToAny BEGIN -->
                        <div class="a2a-kit a2a_kit_size_32 a2a_default_style" :data-a2a-url="link.url">
                          <a class="a2a_button_whatsapp"></a>
                          <a class="a2a_button_line"></a>
                          <a class="a2a_button_wechat"></a>
                          <a class="a2a_button_twitter"></a>
                          <a class="a2a_button_telegram"></a>
                          <a class="a2a_button_facebook"></a>
                          <a class="a2a_button_skype"></a>
                          <a class="a2a_dd" href="https://www.addtoany.com/share"></a>
                        </div>
                        <component :is="'script'" src="https://static.addtoany.com/menu/page.js" async></component>
                        <!-- AddToAny END -->
                        <div class="bottom">
                          {{ $t('referralLinks.Share') }}...
                          <img src="@/assets/images/referralLinks.png" @click="onCopyImg(index)" alt="" />
                        </div>
                      </div>
                    </div>
                    <vue-qrcode :color="color" :value="link.url || GLOBAL_REFERRAL_SITE" :class="index" />
                  </el-tooltip>
                </div>
                <div class="url">
                  <div class="fl">
                    <p>{{ link.url }}</p>
                  </div>
                  <div class="fr" @click="onCopy(link.url)" :data-testid="'copy-' + link.name">
                    <span>{{ $t('referralLinks.cpy') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode';
import AccountNumber from '@/components/form/AccountNumber';

export default {
  name: 'ReferralLinks',
  components: { AccountNumber, VueQrcode },
  data() {
    return {
      language: '',
      languageList: {
        English: { language: this.$t('common.lang.english'), value: '' },
        Chinese: { language: this.$t('common.lang.chinese'), value: 'cn' },
        French: { language: this.$t('common.lang.french'), value: 'fr' },
        Thai: { language: this.$t('common.lang.thai'), value: 'th' },
        German: { language: this.$t('common.lang.german'), value: 'de' },
        Spanish: { language: this.$t('common.lang.spanish'), value: 'es' },
        Malay: { language: this.$t('common.lang.malay'), value: 'my' },
        Vietnamese: { language: this.$t('common.lang.viet'), value: 'vn' },
        Indonesian: { language: this.$t('common.lang.indo'), value: 'id' },
        Arabic: { language: this.$t('common.lang.arabic'), value: 'ar' }
      },
      linkList: {
        liveAccountLink: { name: this.$t('referralLinks.liveAcc'), url: '' },
        demoAccountLink: { name: this.$t('referralLinks.demoAcc'), url: '' },
        homePageLink: { name: this.$t('referralLinks.homePage'), url: '' }
      },
      color: { dark: '#004cb2', light: '#ffffffff' },
      active: ''
    };
  },
  watch: {
    accountID() {
      console.log(this.accountID);
      if (this.accountID) this.setLinkList();
    }
  },
  methods: {
    onCopy(item) {
      this.$copyText(item)
        .then(result => {
          this.$message(this.$t('referralLinks.alreadyCPY'));
        })
        .catch(err => {
          this.$message('Failed to copy' + err);
        });
    },
    async convertBase64ToBlob(base64) {
      const response = await fetch(base64);
      const blob = await response.blob();
      const data = [new ClipboardItem({ [blob.type]: blob })];
      await navigator.clipboard.write(data); //only https
      this.$message(this.$t('referralLinks.alreadyCPY'));
    },
    onCopyImg(name) {
      this.convertBase64ToBlob(document.getElementsByClassName(name)[0].src);
    },
    languageSelectChange(item) {
      if (this.accountID != null) this.setLinkList();
    },
    setLinkList() {
      const vm = this;
      const liveAccount = '/forex-trading-account';
      const demoAccount = '/trading/accounts/demo-account';

      function getMainDomain(type = '') {
        let domain = !vm.language ? '' : vm.language + '.';
        domain += !vm.language ? vm.GLOBAL_REFERRAL_SITE : vm.GLOBAL_REFERRAL_SITE.replace('www.', '');

        // ACM-7664, ACM-7639
        if (vm.language === 'my' || vm.language === 'fr') {
          domain = vm.regulator === 'svg' ? domain.replace('net', 'trading') : domain.replace('com', 'online');
        }

        if (vm.language === 'id') {
          domain = vm.regulator === 'svg' ? domain.replace('net', 'trading') : domain.replace('puprime', 'pu-prime');
        }

        if (vm.language === 'cn') {
          domain = vm.regulator === 'svg' ? 'cn.pusglobal.net' : 'cn.pu-prime.com';
        }

        return `${domain}${type}/?affid=${vm.accountID}`;
      }

      this.linkList.liveAccountLink.url = `${getMainDomain(liveAccount)}`;
      this.linkList.demoAccountLink.url = `${getMainDomain(demoAccount)}`;
      this.linkList.homePageLink.url = `${getMainDomain()}`;
    },
    setDefaultLanuage(countryCode) {
      switch (countryCode) {
        case '1':
          this.language = this.languageList.Chinese.value;
          break;
        case '3416':
          this.language = this.languageList.Arabic.value;
          break;
        case '3915':
          this.language = this.languageList.German.value;
          break;
        case '4101':
          this.language = this.languageList.French.value;
          break;
        case '5015':
          this.language = this.languageList.Malay.value;
          break;
        case '6163':
          this.language = this.languageList.Thai.value;
          break;
        case '6581':
          this.language = this.languageList.Spain.value;
          break;
        case '6877':
          this.language = this.languageList.Indonesian.value;
          break;
        case '6991':
          this.language = this.languageList.Vietnamese.value;
          break;
        default:
          this.language = '';
      }
    }
  },
  mounted() {
    this.setDefaultLanuage(this.$store.state.common.countryCode);
    this.setLinkList();
  },
  computed: {
    accountID() {
      return this.$store.state.behavior.AID;
    },
    lang() {
      return this.$store.state.common.lang;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/referralLinks.scss';
</style>
